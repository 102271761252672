/**
 * external libs
 */
import { useEffect, useMemo, useState, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import qs from "qs";
/**
 * context
 */
import { GlobalContext } from './../../App'
/**
 * utils
 */
import { privateRoutes } from '../../routers/paths'
import useSender from './../../utils/sender'
/**
 * types
 */
import { GlobalContextType, RouteType, GasStationType, GasStationServerType } from '../../types'

export const DEFAULT_COORDINATES = [55.71366, 37.47544];

export const usePrivateLayoutData = () => {
    const { addAlert } = useContext<GlobalContextType>(GlobalContext)
    const { http } = useSender()
    const location = useLocation()
    const [gasStaions, setGasStation] = useState<GasStationType[] | null>(null)
    const [fuelFilter, setFuelFilter] = useState<string[]>([])
    const [servicesFilter, setServicesFilter] = useState<string[]>([])
    const [coordinatesFilter, setCoordinatesFilter] = useState<number[]>(DEFAULT_COORDINATES)
    const [ymapsObject, setYmapsObject] = useState<object | null>(null)

    const routeData = useMemo<RouteType | undefined>(
        () => privateRoutes.find((r: RouteType) => r.path === location.pathname),
        [location]
    )

    const isMap = useMemo(() => location.pathname === '/map', [location.pathname])

    useEffect(() => {
        const getStationsData = async () => {
            try {
                const extraPartOfUrl = JSON.stringify({
                    coordinates: coordinatesFilter.join(" "),
                    services: [
                        ...fuelFilter,
                        ...servicesFilter,
                    ]
                });
                
                const { data } = await http.get(`/stations?filters=${extraPartOfUrl}`)

                setGasStation(
                    (data as GasStationServerType[]).map((value: GasStationServerType) => ({
                        ...value,
                        coordinates: value.coordinates.split(' ').map((coord) => Number(coord)),
                    }))
                )
            } catch (e) {
                if (addAlert) {
                    addAlert({ text: 'Ошибка запроса', type: 'error' })
                }
            }
        }

        getStationsData()
    }, [setGasStation, addAlert, fuelFilter, servicesFilter, coordinatesFilter])

    return { routeData, isMap, gasStaions, coordinatesFilter, fuelFilter, setFuelFilter, servicesFilter, setServicesFilter, setCoordinatesFilter, ymapsObject, setYmapsObject }
}
