import React from 'react'

export type RouteType = {
    path: string
    exact: boolean
    component: React.FC<any> & { preload?: () => void }
    label?: string | JSX.Element
    parent?: string
    children?: any[]
    title?: string
    description?: string
    seoKeys?: string[]
    subtitle?: string
    isBread?: boolean
    wrapperType?: string
}

export type SelectOptionType = {
    id: number | string
    label: string
}

export enum RolesType {
    individuals = 'individuals',
    companies = 'companies',
    drivers = 'drivers',
}

export enum RolesTranslateType {
    individuals = 'Физическое лицо',
    companies = 'Юридическое лицо',
    drivers = 'Водитель',
}

export type AlertContentType = {
    type: 'success' | 'info' | 'error'
    text: string
}

export type AlertType = AlertContentType & {
    id: number
}

export type UserContextType = {
    uName: string | null
    uID: string | null
    Role: keyof typeof RolesType | null
};

export type GlobalContextType = {
    // Role: keyof typeof RolesType | null
    // changeRole: React.Dispatch<React.SetStateAction<keyof typeof RolesType | null>> | null
    addAlert: ((alert: AlertContentType) => void) | null
    // uID: string | null
    // changeUID: React.Dispatch<React.SetStateAction<string | null>> | null
    // uName: string | null
    // changeUName: React.Dispatch<React.SetStateAction<string | null>> | null
    cards: CardType[] | null
    setCards: React.Dispatch<React.SetStateAction<CardType[] | null>> | null
    user: UserContextType | null
    changeUser: React.Dispatch<React.SetStateAction<UserContextType | null>> | null
}

export type cardUserType = {
    id: string
    name: string
    balance: string
    card: {
        number: string
    }
}

export type BalanceType = {
    id: string
    monthlyLimit: number
    dailyLimit: number
    sizePurse: number
    individualLimit: boolean
    idCircuit: number
    descriptionCircuit: string
    serviceId: string
    serviceName: string
    sizeAccount: number;
}

export type CardType = {
    id: string
    cardNumber: string
    idCondition: number
    descriptionCondition: string
    cardDate: string
}

export type AuthType = {
    login: string
    password: string
}

export type RecoveryType = {
    phone: string
}

export type AreaType = {
    id: string
    cardNumber?: string
    fio?: string
    company?: string
    inn?: string
    role?: keyof typeof RolesType
}

export type UserAreasType = {
    [RolesType.companies]?: AreaType[]
    [RolesType.individuals]?: AreaType[]
    [RolesType.drivers]?: AreaType[]
}

export type ChatMessageRowType = {
    id: string
    text: string
    createdAt?: string
    updatedAt?: string
    userId: string
}

export type ChatMessageType = {
    [key: string]: ChatMessageRowType[]
}

export type OperationType = {
    id: string
    date: string
    sumForWhat: number
    sumThan: number
    operation: number
    serviceForWhatId: string
    serviceForWhatName: string
}

export type OperationsType = {
    [key: string]: OperationType[]
}

export type WalletType = {
    id: string
    monthlyLimit: number
    dailyLimit: number
    sizePurse: number
    individualLimit: boolean
    idCircuit: number
    descriptionCircuit: string
    serviceId: string
    serviceName: string
}

export enum FuelType {
    fuel92 = 'АИ-92',
    fuel95 = 'АИ-95',
    fuel95plus = 'АИ-95+',
    fuel100ps = 'АИ-100 ПС',
}

export const FuelColorType = {
    [FuelType.fuel92]: '255, 208, 0',
    [FuelType.fuel95]: '52, 170, 10',
    [FuelType.fuel95plus]: '240, 68, 56',
    [FuelType.fuel100ps]: '43, 111, 243',
}

export type CardDriverType = {
    id: string
    fio: string
    phone: string
    createdAt: string
    addedDateTime?: string
}

export type CardWithDriverType = CardType & { drivers: CardDriverType[] }

export type DriverType = {
    id: string
    fio: string
    phone: string
    createdAt: string
}

export type DriverWithCardsType = DriverType & { cards: CardType[] }
export type DriverWithCardType = DriverType & { card: CardType }

export type DriverFormType = {
    fio: string
    phone: string
    cards?: (CardType & { label: string })[] | null
}

export type GasStationType = {
    id: string
    supplier: string
    address: string
    services: string[]
    coordinates: number[]
    distance: number
}

export type GasStationServerType = GasStationType & { coordinates: string }

export type ServiceType = {
    id: string
    unitLetter: string
    nameService: string
    unitOfMeasurements: string
    color?: string
}

export type MapGlobalContextType = {
    viewType: number
    setViewType: React.Dispatch<React.SetStateAction<number>>
    filterOpen: boolean
    setFilterOpen: React.Dispatch<React.SetStateAction<boolean>>
    gasStaions: GasStationType[] | null
    coordinatesFilter: number[]
    fuelFilter: string[]
    setFuelFilter: React.Dispatch<React.SetStateAction<string[]>>
    servicesFilter: string[]
    setServicesFilter: React.Dispatch<React.SetStateAction<string[]>>
    setCoordinatesFilter: React.Dispatch<React.SetStateAction<number[]>>
    ymapsObject: object | null
    setYmapsObject: React.Dispatch<React.SetStateAction<object | null>>
}

export type UserType = {
    id: string
    fio?: string
    phone: string
    name?: string
    inn?: string
    kpp?: string
    okpo?: string
    email?: string
    contactPerson?: string
    createdAt?: string
}
