/**
 * external libs
*/
import { createContext, useState, useRef, useCallback } from 'react';
import { HelmetProvider } from 'react-helmet-async';
/**
 * components
*/
import Router from './routers/Router';
import Alerts from "./common-components/alerts/Alerts";
/**
 * types
*/
import { RolesType, GlobalContextType, AlertType, AlertContentType, CardType, UserContextType } from "./types";
/**
 * styles
*/
import './global.scss';

export const GlobalContext = createContext<GlobalContextType>({
  // Role: null,
  // changeRole: null,
  addAlert: null,
  // uID: null,
  // changeUID: null,
  // uName: null,
  // changeUName: null,
  cards: null,
  setCards: null,
  user: null,
  changeUser: null,
});

function App() {
  // const [userType, setUserType] = useState<keyof typeof RolesType | null>(null);
  // const [uID, setUID] = useState<string | null>(null);
  // const [uName, setUName] = useState<string | null>(null);
  const [alerts, setAlerts] = useState<AlertType[]>([]);
  const [cards, setCards] = useState<CardType[] | null>(null)
  const [user, setUser] = useState<UserContextType | null>(null);
  const alertId = useRef<number>(0);
  
  const addAlert = useCallback((alert: AlertContentType) => {
    alertId.current = alertId.current + 1;
    setAlerts(pre => [...pre, {...alert, id: alertId.current}]);
  }, [alertId]);

  return (
    <GlobalContext.Provider value={{ user, changeUser: setUser, addAlert, cards, setCards }}>
      <Alerts alerts={alerts} setAlerts={setAlerts}/>

      <HelmetProvider>
        <Router />
      </HelmetProvider>
    </GlobalContext.Provider>
  );
}

export default App;
