/**
 * external libs
 */
import React from 'react'
/**
 * components
 */
import ChevronLeft from './../../assets/icons/chevron-left.svg'
/**
 * types
 */
import { DriverWithCardsType } from './../../types'
/**
 * styles
 */
import styles from './driver.module.scss'

type Props = {
    driver: DriverWithCardsType
    handler: React.Dispatch<any>
}

const Driver: React.FC<Props> = ({ driver, handler }) => {
    if (!driver.cards.length) {
        return null
    }

    return (
        <>
            {
                driver.cards.map(card => (
                    <div className={styles.driver} onClick={handler} key={`${driver.id}_${card.id}`}>
                        <div className={styles.driver__data}>
                            {!!driver.fio && <p className={styles.driver__name}>{driver.fio}</p>}

                            <div className={styles.driver__card}>
                                <p className={styles.driver__label}>Карта:</p>

                                <div className={styles.driver__numberBG}>
                                    <p className={styles.driver__number}>{card.cardNumber || ''}</p>
                                </div>
                            </div>
                        </div>

                        <img src={ChevronLeft} alt={driver.fio} className={styles.driver__arrow} />
                    </div>
                ))
            }
        </>
    )
}

export default Driver
