/**
 * external libs
*/
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
/**
 * components
*/
import Button from "../../common-components/button/Button";
/**
 * context
*/
import { GlobalContext } from "./../../App";
/**
 * types
*/
import { GlobalContextType } from "./../../types";
/**
 * styles
*/
import styles from './error-pages.module.scss';


const Patients: React.FC = () => {
    const { user } = useContext<GlobalContextType>(GlobalContext);
    const navigate = useNavigate();

    return (
        <div className={styles.page404}>
            <div className={styles.page404__header}>
                <p className={styles.page404__title}>{"404 :("}</p>
                <p className={styles.page404__error}>Ошибка</p>
            </div>

            <div className={styles.page404__footer}>
                <div className={styles.page404__texts}>
                    <p className={styles.page404__description}>К сожалению, запрашиваемая вами страница, не найдена</p>
                    <p className={styles.page404__propose}>Попробуйте вернуться на главную</p>
                </div>

                <Button
                    label="Вернуться на главную"
                    type="button"
                    subClasses={styles.page404__button}
                    handler={() => navigate(user?.Role ? "/office" : "/", { replace: true })}
                />
            </div>
        </div>
    );
};

export default Patients;