/**
 * external libs
*/
import React, { useState } from "react";
import { addDays } from 'date-fns';
import { Range } from 'react-date-range';
/**
 * components
*/
import MenuLink from "../common-components/menu-link/MenuLink";
import HTitle from "../common-components/texts/HTitle";
import DropDown from "../common-components/drop-down/DropDown";
import DatepickerRange from "../common-components/datepicker-range/DatepickerRange";
import Button from "../common-components/button/Button";
import Input from "../common-components/input/Input";
import Field from "../common-components/field/Field";
/**
 * types
*/
import { SelectOptionType } from "./../types";


const UIKits: React.FC = () => {
    const [valuesOfDropDown, setValuesOfDropDown] = useState<SelectOptionType[]>([]);
    const [dpRange, setDPRange] = useState<Range[]>([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 7),
            key: 'selection'
        }
    ]);

    return (
        <div className="container" style={{ display: 'flex', flexDirection: 'column', rowGap: '3rem' }}>
            <p>UI Kits</p>

            {/* Titles */}
            <HTitle type="h1" text="Главная" />
            <HTitle type="h2" text="Расходы и пополнения" />
            <HTitle type="p" text="Пинкод выдается вместе с картой, его невозможно поменять на свой!" />

            {/* Item of menu */}
            <MenuLink
                to="/"
                title="Главная"
                ico={<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 9L12 2L21 9V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V9Z" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9 22V12H15V22" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
                </svg>}
                fillType="stroke"
            />

            {/* Item of menu with count */}
            <MenuLink
                to="/"
                title="Уведомления:"
                ico={<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.6007 14.9633C18.9226 13.7969 18.5624 12.1227 18.5624 10.125C18.5624 8.38452 17.871 6.71532 16.6403 5.48461C15.4096 4.2539 13.7404 3.5625 11.9999 3.5625C10.2594 3.5625 8.59022 4.2539 7.35951 5.48461C6.1288 6.71532 5.4374 8.38452 5.4374 10.125C5.4374 12.1234 5.07881 13.7969 4.40068 14.9633C4.26231 15.2012 4.18896 15.4713 4.188 15.7465C4.18704 16.0218 4.2585 16.2924 4.39521 16.5312C4.53106 16.7702 4.72823 16.9687 4.96637 17.106C5.20452 17.2433 5.475 17.3146 5.7499 17.3125H8.57646C8.65398 18.1669 9.04819 18.9614 9.68164 19.54C10.3151 20.1186 11.142 20.4394 11.9999 20.4394C12.8578 20.4394 13.6847 20.1186 14.3182 19.54C14.9516 18.9614 15.3458 18.1669 15.4233 17.3125H18.2499C18.5244 17.3142 18.7944 17.2427 19.0321 17.1054C19.2698 16.9681 19.4666 16.7699 19.6022 16.5312C19.7395 16.2927 19.8117 16.0223 19.8114 15.7471C19.8111 15.4718 19.7385 15.2015 19.6007 14.9633ZM11.9999 18.5625C11.6397 18.5626 11.2905 18.4382 11.0115 18.2104C10.7325 17.9826 10.5407 17.6654 10.4686 17.3125H13.5312C13.4591 17.6654 13.2673 17.9826 12.9883 18.2104C12.7093 18.4382 12.3601 18.5626 11.9999 18.5625ZM6.27021 15.4375C6.96162 14.0312 7.3124 12.2453 7.3124 10.125C7.3124 8.8818 7.80626 7.68951 8.68534 6.81044C9.56441 5.93136 10.7567 5.4375 11.9999 5.4375C13.2431 5.4375 14.4354 5.93136 15.3145 6.81044C16.1935 7.68951 16.6874 8.8818 16.6874 10.125C16.6874 12.2445 17.0374 14.0312 17.7288 15.4375H6.27021Z" />
                </svg>}
                count={4}
            />

            {/* Buttons */}
            <div style={{display: "flex", columnGap: "2rem"}}>
                <Button label="Отмена" handler={() => {}} buttonStyle="outline"/>
                <Button label="Принять" handler={() => {console.log("1111")}}/>
            </div>

            {/* Drop downs */}
            <DropDown
                values={valuesOfDropDown}
                changeHandler={vals => setValuesOfDropDown(vals)}
                placeholder="Тип операции"
                options={[
                    {
                        id: 1,
                        label: 'Leanne Graham'
                    },
                    {
                        id: 2,
                        label: 'Ervin Howell'
                    }
                ]}
            />

            <DropDown
                values={valuesOfDropDown}
                changeHandler={vals => setValuesOfDropDown(vals)}
                placeholder="Тип операции"
                isGray={true}
                options={[
                    {
                        id: 1,
                        label: 'Leanne Graham'
                    },
                    {
                        id: 2,
                        label: 'Ervin Howell'
                    }
                ]}
            />

            {/* Date picker range */}
            <DatepickerRange
                handleSelect={setDPRange}
                dpRange={dpRange}
                label="Сегодня"
                ico={<svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.3333 1.6665V4.99984M6.66667 1.6665V4.99984M2.5 8.33317H17.5M4.16667 3.33317H15.8333C16.7538 3.33317 17.5 4.07936 17.5 4.99984V16.6665C17.5 17.587 16.7538 18.3332 15.8333 18.3332H4.16667C3.24619 18.3332 2.5 17.587 2.5 16.6665V4.99984C2.5 4.07936 3.24619 3.33317 4.16667 3.33317Z" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                </svg>}
            />

            {/* Input */}
            <Input placeholder="100" value="" change={() => {}}/>

            {/* Fields */}
            <Field label="Field label">
                <Input placeholder="100" value="" change={() => {}}/>
            </Field>
            <Field label="Field label" error="Field error">
                <Input placeholder="100" value="" change={() => {}}/>
            </Field>
            <Field label="Field label">
                <DropDown
                    values={valuesOfDropDown}
                    changeHandler={vals => setValuesOfDropDown(vals)}
                    placeholder="Тип операции"
                    isGray={true}
                    options={[
                        {
                            id: 1,
                            label: 'Leanne Graham'
                        },
                        {
                            id: 2,
                            label: 'Ervin Howell'
                        }
                    ]}
                />
            </Field>

            <Field label="Field label" error="Field error">
                <DropDown
                    values={valuesOfDropDown}
                    changeHandler={vals => setValuesOfDropDown(vals)}
                    placeholder="Тип операции"
                    isGray={true}
                    options={[
                        {
                            id: 1,
                            label: 'Leanne Graham'
                        },
                        {
                            id: 2,
                            label: 'Ervin Howell'
                        }
                    ]}
                />
            </Field>
        </div>
    );
};

export default UIKits;