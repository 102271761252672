/**
 * external libs
*/
import React from "react";
/**
 * components
*/
import AllDrivers from "./AllDrivers";
import ViewDriver from "./ViewDriver";
import EditDriver from "./EditDriver";
import EditCard from "./EditCard";
/**
 * types
*/
import { DriverWithCardsType, CardType } from "./../../../../types";

type Props = {
    drivers: DriverWithCardsType[];
    activeDriver: DriverWithCardsType | null;
    setActiveDriver: React.Dispatch<React.SetStateAction<DriverWithCardsType | null>>;
    editDriver: boolean;
    setEditDriver: React.Dispatch<React.SetStateAction<boolean>>;
    editCard: CardType | null;
    setEditCard: React.Dispatch<React.SetStateAction<CardType | null>>;
    setDrivers: React.Dispatch<React.SetStateAction<DriverWithCardsType[] | null>>;
};

const DriversPortal: React.FC<Props> = ({ drivers, setDrivers, activeDriver, setActiveDriver, editDriver, setEditDriver, editCard, setEditCard }) => {
    return (
        <>
            {
                editDriver
                ? <EditDriver
                    driver={activeDriver}
                    setEdit={setEditDriver}
                    setDrivers={setDrivers}
                />
                : <>
                    {
                        !activeDriver
                            ? <AllDrivers drivers={drivers} setActiveDriver={setActiveDriver} setEdit={setEditDriver}/>
                            : (
                                !!editCard
                                    ? <EditCard
                                        card={editCard}
                                        setEditCard={setEditCard}
                                    />
                                    : <ViewDriver
                                        driver={activeDriver}
                                        setActiveDriver={setActiveDriver}
                                        setEdit={setEditDriver}
                                        setEditCard={setEditCard}
                                    />
                            )
                    }
                </>
            }
        </>
    );
};

export default DriversPortal;