/**
 * external libs
*/
import React from "react";
/**
 * components
*/
import Driver from "../../../../common-components/driver/Driver";
import Button from "../../../../common-components/button/Button";
/**
 * types
*/
import { DriverWithCardsType, CardType } from "./../../../../types";
/**
 * styles
*/
import styles from './../../../layouts.module.scss';

type Props = {
    driver: DriverWithCardsType;
    setActiveDriver: React.Dispatch<React.SetStateAction<DriverWithCardsType | null>>;
    setEdit: React.Dispatch<React.SetStateAction<boolean>>;
    setEditCard: React.Dispatch<React.SetStateAction<CardType | null>>;
};

const ViewDriver: React.FC<Props> = ({ driver, setActiveDriver, setEdit, setEditCard }) => {
    return (
        <div className={styles.drivers__edit}>
            {/* <button
                type="button"
                onClick={() => setEdit(true)}
                className={styles.drivers__addDriver}
            >
                <span className={styles.drivers__addDriverText}>Редактировать</span>
            </button> */}

            <div className={styles.drivers__partWrapper}>
                <p className={styles.drivers__partTitle}>Топливные карты: <span className={styles.drivers__partTitle_blue}>{(driver.cards || []).length}</span></p>

                <div className={styles.drivers__cardsListScroll}>
                    <div className={styles.drivers__cardsListWrapper}>
                        {
                            (driver.cards || []).map(card => (
                                <Driver key={card.id} driver={{...driver, cards: [card]}} handler={() => setEditCard(card)}/>
                            ))
                        }
                    </div>
                </div>
            </div>

            <Button
                label="Назад"
                type="button"
                subClasses={styles.drivers__button}
                handler={() => setActiveDriver(null)}
            />
        </div>
    );
};

export default ViewDriver;