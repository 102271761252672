/**
 * external libs
 */
import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment'
/**
 * components
 */
import DateComponent from './Date'
import Item from './Item'
import ChatIco from './../../../assets/icons/chat.svg'
/**
 * context
 */
import { GlobalContext } from './../../../App'
/**
 * utils
 */
import useSender from './../../../utils/sender'
/**
 * types
 */
import { ChatMessageRowType, GlobalContextType, ChatMessageType } from './../../../types'
/**
 * styles
 */
import styles from './chat.module.scss'

export const ChatTitle: React.FC = () => (
    <div className={styles.chat__titleWrapper}>
        <img src={ChatIco} alt="chat" className={styles.chat__ico} />
        <p className={styles.chat__title}>Чат с техподдержкой</p>
    </div>
)

const Chat: React.FC = () => {
    const { http } = useSender()
    const { addAlert, user } = useContext<GlobalContextType>(GlobalContext)

    const [messageText, setMessageText] = useState<string>('')
    const [messages, setMessages] = useState<ChatMessageType | null>(null)

    const sendMessage = async () => {
        try {
            const key = moment().format('DD.MM.YYYY')

            await http.post('/support/chat', {text: messageText})

            setMessages((pre) => ({
                ...pre,
                [key]: [
                    ...(pre && pre[key] ? pre[key] : []),
                    {
                        id: String(Date.now()),
                        text: messageText,
                        createdAt: moment().format('YYYY-MM-DD hh:mm:ss'),
                        userId: user?.uID || '',
                    },
                ],
            }))
            setMessageText('')
        } catch (e) {
            if (addAlert) {
                addAlert({ text: 'Ошибка запроса', type: 'error' })
            }
        }
    }

    useEffect(() => {
        const getMessage = async () => {
            try {
                const { data } = await http.get(`/support/chat`)
                
                const transformData = ((data as ChatMessageRowType[]) || []).reduce(
                    (accum: ChatMessageType, item: ChatMessageRowType) => {
                        const key = moment(item.createdAt, 'YYYY-MM-DD hh:mm:ss').format('DD.MM.YYYY')
                        const addingItem = {
                            ...item,
                            createdAt: item.createdAt ? moment(item.createdAt, 'YYYY-MM-DD hh:mm:ss').format('hh:mm') : "",
                            updatedAt: item.updatedAt ? moment(item.updatedAt, 'YYYY-MM-DD hh:mm:ss').format('hh:mm') : "",
                        }
                        accum[key] = accum[key] ? [...accum[key], addingItem] : [addingItem]
                        return accum
                    },
                    {}
                )

                setMessages(transformData)
            } catch (e) {
                if (addAlert) {
                    addAlert({ text: 'Ошибка запроса', type: 'error' })
                }
            }
        }

        getMessage()
    }, [setMessages, user])

    if (messages === null) {
        return null
    }

    return (
        <div className={styles.chat__wrapper}>
            <div className={styles.chat__content}>
                {Object.keys(messages).map((date) => (
                    <React.Fragment key={date}>
                        <DateComponent date={date} />

                        {messages[date].map((item) => (
                            <Item
                                key={item.id}
                                message={item.text}
                                date={item.createdAt || item.updatedAt || ""}
                                type={item.userId === user?.uID ? 'outcoming' : 'incoming'}
                            />
                        ))}
                    </React.Fragment>
                ))}
            </div>

            <div className={styles.chat__footer}>
                <input
                    className={styles.chat__input}
                    placeholder="Ваше сообщение"
                    value={messageText}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMessageText(e.target.value)}
                />

                <button
                    type="button"
                    className={`${styles.chat__button} ${!messageText.length ? styles.chat__button_disabled : ''}`}
                    disabled={!messageText.length}
                    onClick={sendMessage}>
                    <svg
                        className={styles.chat__buttonIco}
                        viewBox="0 0 20 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.5 15.5L12.5 10.5L7.5 5.5"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </button>
            </div>
        </div>
    )
}

export default Chat
