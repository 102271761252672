/**
 * external libs
*/
import React from "react";
import Select from "react-dropdown-select";
/**
 * types
*/
import { SelectOptionType } from "./../../types";

type Props<T> = {
    options: T[];
    changeHandler: (values: T[]) => void;
    values: T[] | any;
    multi?: boolean;
    placeholder?: string;
    searchable?: boolean;
    isGray?: boolean;
    isError?: boolean;
};

function DropDown<T extends SelectOptionType>(props: Props<T>) {
    const {options, changeHandler, values = [], multi = false, placeholder = "", searchable = false, isGray = false, isError = false} = props;

    return (
        <Select
            values={values}
            options={options}
            onChange={changeHandler}
            multi={multi}
            placeholder={placeholder}
            searchable={searchable}
            className={`dropdown ${isGray ? "dropdown_gray" : ""} ${isError ? "dropdown_error" : ""}`}
            valueField="id"
            dropdownGap={0.1}
            dropdownPosition="auto"
        />
    );
};

export default DropDown;