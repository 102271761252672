/**
 * external libs
 */
import React, { PropsWithChildren, useMemo, useState, useEffect, useCallback, useContext } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
/**
 * components
*/
import Logo from "./../../assets/images/logo.png";
/**
 * types
*/
import { RouteType } from "../../types";
/**
 * utils
*/
import { publicRoutes } from "../../routers/paths";
/**
 * context
 */
import { GlobalContext } from './../../App'
/**
 * types
 */
import { GlobalContextType } from './../../types'
/**
 * styles
*/
import styles from './../layouts.module.scss';

const PublicLayout: React.FC<PropsWithChildren> = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const [access, setAccess] = useState(false)
    const { user, changeUser } = useContext<GlobalContextType>(GlobalContext)

    const routeData = useMemo<RouteType | undefined>(() =>
        publicRoutes.find(
            (r: RouteType) => r.path === location.pathname
        ), [location]
    );

    const firstVisitHandler = useCallback(() => {
        const accessToken = sessionStorage.getItem('accessToken') || localStorage.getItem('accessToken')
        const refreshToken = sessionStorage.getItem('refreshToken') || localStorage.getItem('refreshToken')
        const uIDLocal = localStorage.getItem('uid')
        const roleLocal = localStorage.getItem('urole')

        if ((user?.uID && user?.Role) || (accessToken && refreshToken && uIDLocal && roleLocal && changeUser)) {
            navigate('/office')
        } else {
            sessionStorage.removeItem("accessToken")
            localStorage.removeItem("accessToken")
            sessionStorage.removeItem("refreshToken")
            localStorage.removeItem("refreshToken")
            localStorage.removeItem("uid")
            localStorage.removeItem("urole")
            setAccess(true)
        }
    }, [user, setAccess, changeUser])

    useEffect(() => {
        firstVisitHandler()
    }, [firstVisitHandler])

    if (!access) {
        return null
    }

    return (
        <>
            <Helmet>
                <title>{routeData?.title ?? "404"}</title>
                <meta name="description" content={routeData?.description ?? ""} />
            </Helmet>

            <div className={styles.publicLayout}>
                <main className={styles.publicLayout__main}>
                    <Outlet />
                </main>
                
                <div className={styles.publicLayout__bg}>
                    <div className={styles.publicLayout__logoWrap}>
                        <img src={Logo} alt="Logo" className={styles.publicLayout__logo}/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PublicLayout;