/**
 * external libs
*/
import React from "react";
/**
 * components
*/
import Home from "../pages/home/HomeLazy";
import Office from "../pages/office/OfficeLazy";
import Recovery from "../pages/recovery/RecoveryLazy";
import Alerts from "../pages/alerts/AlertsLazy";
import Profile from "../pages/profile/ProfileLazy";
import FuelCards from "../pages/fuel-cards/FuelCardsLazy";
import Map from "../pages/map/MapLazy";
/**
 * types
*/
import { RouteType } from "../types";

export const publicRoutes: RouteType[] = [
    { path: "/", exact: true, component: Home, title: "Home", description: "Home" },
    { path: "/recovery", exact: true, component: Recovery, title: "Recovery", description: "Recovery" },
];

export const privateRoutes: RouteType[] = [
    { path: "/office", exact: true, component: Office, title: "Office", description: "Office" },
    { path: "/alerts", exact: true, component: Alerts, title: "Alerts", description: "Alerts" },
    { path: "/appeals", exact: true, component: Alerts, title: "Appeals", description: "Appeals" },
    { path: "/profile", exact: true, component: Profile, title: "Profile", description: "Profile" },
    { path: "/fuel-cards", exact: true, component: FuelCards, title: "Fuel Cards", description: "Fuel Cards" },
    { path: "/map", exact: true, component: Map, title: "Map", description: "Map" },
];

